import React from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql, type PageProps } from 'gatsby';

import { IS_EN_LANG_ENV } from 'config/env';
import { pages } from 'constants/index';
import { Layout } from 'modules/ui';
import { BlogPostsPage, BlogSeo } from 'modules/blog';
import type { BlogPost, BlogPostsPageContext } from 'modules/blog/types';

interface DataProps {
    allStrapiPost: {
        nodes: BlogPost[];
    };
    allStrapiCategory: { nodes: any[] };
}

const Blog = ({ data, pageContext }: PageProps<DataProps, BlogPostsPageContext>) => (
    <>
        <BlogSeo
            pageId={pages.BLOG}
            titleTemplate={IS_EN_LANG_ENV ? `%s | Ackee #${pageContext.currentPage}` : `%s #${pageContext.currentPage}`}
        />
        <Layout>
            <BlogPostsPage
                title={<FormattedMessage id="blog.title" />}
                description={<FormattedMessage id="blog.description" />}
                posts={data.allStrapiPost.nodes}
                categories={data.allStrapiCategory.nodes}
                basePath="/blog"
                pageContext={pageContext}
            />
        </Layout>
    </>
);
// filter: { locale: { eq: "en-US" } }
export const query = graphql`
    query ($skip: Int!, $limit: Int!, $localeRegex: String) {
        allStrapiPost(
            sort: { fields: publishedAt, order: DESC }
            limit: $limit
            skip: $skip
            filter: { locale: { regex: $localeRegex } }
        ) {
            nodes {
                ...BlogPost
            }
        }
        allStrapiCategory(sort: { fields: title, order: ASC }, filter: { locale: { regex: $localeRegex } }) {
            nodes {
                slug
                title
                locale
            }
        }
    }
`;

export default Blog;
